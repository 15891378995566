<template>
  <div class="mx-6 px-16">
    <v-card
      dark
      color="transparent"
    >
      <v-card-title>
        <v-row>
          <v-col
            cols="12"
            md="3"
            class="py-0"
          >
            <v-date-range-picker
              v-model="filters.dateRange"
              dark
              @change="loadItems"
            />
          </v-col>
          <v-col
            cols="12"
            md="3"
            class="py-0"
          >
            <v-select
              v-model="filters.types"
              :items="sampleTypes"
              label="Tipo"
              prepend-inner-icon="biotech"
              clearable
              multiple
              filled
              hide-details
            />
          </v-col>
          <v-spacer />
          <v-col
            cols="12"
            md="5"
            class="py-0"
          >
            <v-text-field
              v-model="filters.search"
              prepend-inner-icon="search"
              dark
              label="Procurar"
              filled
              hide-details
            />
          </v-col>
        </v-row>
      </v-card-title>
      <data-table
        ref="report"
        name="Coleta de Normativa"
        dark
        dense
        :items="filteredItems"
        :headers="headers"
      >
        <template #[`item.avisado`]="{ item }">
          <v-checkbox
            v-model="item.avisado"
            @click.capture="atualizaResultado(item)"
          />
        </template>
        <template #[`item.resultado`]="{ item }">
          <v-text-field
            v-model="item.resultado"
            autocomplete="off"
            @focus="resultadoSelecionado(item)"
            @blur="atualizaResultado(item)"
          />
        </template>

        <template #[`item.marca_comercial`]="{ item }">
          <v-autocomplete
            v-if="item.antibioticName?.id !== 'OTHER'"
            :ref="`antibioticName_${item.id_coletas_amostra}`"
            v-model="item.antibioticName"
            autocomplete="off"
            :items="medicines"
            item-value="id"
            item-text="description"
            placeholder="Medicamento"
            return-object
            clearable
            append-outer-icon="edit"
            @click:append-outer="toggleEdit(item, 'antibioticName')"
            @focus="resultadoSelecionado(item)"
            @blur="atualizaResultado(item)"
          />
          <v-text-field
            v-else
            :ref="`antibioticName_${item.id_coletas_amostra}`"
            v-model="item.antibioticName.description"
            append-outer-icon="touch_app"
            @click:append-outer="toggleEdit(item, 'antibioticName')"
            @focus="resultadoSelecionado(item)"
            @blur="atualizaResultado(item)"
          />
        </template>

        <template #[`item.imagens`]="{ item }">
          <v-btn
            icon
            @click="openGallery(item.anexos)"
          >
            <div v-if="!item.anexos || (item.anexos && JSON.parse(item.anexos).length < 1)">
              <v-tooltip top>
                <template #activator="{ on }">
                  <div v-on="on">
                    <v-icon disabled>
                      image
                    </v-icon>
                  </div>
                </template>
                Sem Anexos
              </v-tooltip>
            </div>
            <div v-else>
              <v-icon>
                image
              </v-icon>
            </div>
          </v-btn>
        </template>
        <template #[`item.actions`]="{ item }">
          <v-menu
            bottom
            right
          >
            <template #activator="{ on }">
              <v-btn
                icon
                v-on="on"
              >
                <v-icon>more_vert</v-icon>
              </v-btn>
            </template>

            <v-list>
              <v-list-item
                small
                @click="editAnexos(item)"
              >
                <v-list-item-icon>
                  <v-icon>image</v-icon>
                </v-list-item-icon>
                <v-list-item-title>Editar Anexos</v-list-item-title>
              </v-list-item>

              <v-list-item
                small
                @click="excludeSample(item.id_coletas_amostra)"
              >
                <v-list-item-icon>
                  <v-icon>delete</v-icon>
                </v-list-item-icon>
                <v-list-item-title>Excluir</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </template>
      </data-table>
    </v-card>

    <v-speed-dial
      fixed
      dark
      bottom
      right
      open-on-hover
      direction="top"
      transition="slide-y-reverse-transition"
      class="mr-5"
    >
      <template #activator>
        <v-btn
          color="blue darken-2"
          dark
          large
          fab
        >
          <v-icon>menu</v-icon>
        </v-btn>
      </template>
      <v-btn
        fab
        dark
        color="purple darken-1"
        @click.stop="addSample()"
      >
        <v-tooltip left>
          <template #activator="{ on }">
            <v-icon v-on="on">
              add
            </v-icon>
          </template>
          <span>Inserir Nova Amostra</span>
        </v-tooltip>
      </v-btn>
      <v-btn
        fab
        dark
        color=" green darken-1"
        @click="exportExcel()"
      >
        <v-tooltip left>
          <template #activator="{ on }">
            <v-icon v-on="on">
              backup_table
            </v-icon>
          </template>
          Download (Excel)
        </v-tooltip>
      </v-btn>

      <v-btn
        fab
        dark
        color="orange darken-1"
        @click="print()"
      >
        <v-tooltip left>
          <template #activator="{ on }">
            <v-icon v-on="on">
              print
            </v-icon>
          </template>

          Imprimir
        </v-tooltip>
      </v-btn>
    </v-speed-dial>

    <add-sample-dialog
      v-model="showAddSample"
      @save="loadItems"
    />

    <LightBox
      v-if="pictures.length > 0"
      ref="lightbox"
      :media="pictures"
      :show-light-box="false"
    />

    <anexos-dialog
      v-model="showAnexos"
      :form="form"
      @save="loadItems"
    />
  </div>
</template>

<script>
import _ from 'lodash';
import moment from "moment-timezone";
import VDateRangePicker from "@/Support/Components/VDateRangePicker.vue";
import AddSampleDialog from "@/Domains/Collects/Samples/Components/AddSampleDialog.vue";
import AnexosDialog from "@/Domains/Collects/Samples/Components/AnexosDialog.vue";
import LightBox from 'vue-image-lightbox';

export default {

  components: {
    VDateRangePicker,
    AddSampleDialog,
    AnexosDialog,
    LightBox,
  },

  data() {
    return {
      filters: {
        dateRange: [moment().format('YYYY-MM-DD'), moment().format('YYYY-MM-DD')],
        types: [],
        search: null,
      },

      headers: [
        { text: 'Avisado', value: 'avisado', align: 'start', sortable: false, formatter: value => value ? 'Sim' : 'Não' },
        { text: 'Código', value: 'codigo_laticinio', align: 'center' },
        { text: 'Produtor', value: 'nome_produtor' },
        { text: 'Tipos', value: 'tipos', formatter: value => value.join(', ') },
        { text: 'Amostra', value: 'numero_amostra', align: 'center' },
        { text: 'Observação', value: 'observacao' },
        { text: 'Data', value: 'data', align: 'center' },
        { text: 'Resultado', value: 'resultado' },
        { text: 'Medicamento', value: 'marca_comercial', show: false, align: 'center'  },
        { text: 'Requerimento de amostra / fotos', value: 'imagens', align: 'center' },
        { text: 'Opções', altText: 'Opções', value: 'actions', sortable: false, align: 'center' },
      ],

      items: [],

      showAddSample: false,

      pictures: [],

      form: {},

      showAnexos: false,

      medicines: [],

      itemsToUpdate: {},
    };
  },

  computed: {
    filteredItems() {
      if (!this.filters.search && !this.filters.types?.length) {
        return this.items;
      }

      const search = String(this.filters.search).toUpperCase().trim();
      const types = this.filters.types || [];
      return this.items.filter(item => {
        const hasSearch = !search || JSON.stringify(Object.values(item)).toUpperCase().includes(search);
        const hasTypes = !types.length || item.tipos.some(type => types.includes(type));
        return hasSearch && hasTypes;
      });
    },

    sampleTypes() {
      const types = this.$store.state.settings.gerais?.tipos_coleta_amostra || [];

      return types.filter(type => type !== 'ANTIBIOTICOS')
    },
  },

  created() {
    this.loadMedicines();
  },

  methods: {
    // Controla os campos de edição, para que não atualize o tempo todo, enquanto ainda está sendo preenchido os campos
    resultadoSelecionado({ id_coletas_amostra }) {
      if (this.itemsToUpdate[id_coletas_amostra] !== undefined) {
        clearTimeout(this.itemsToUpdate[id_coletas_amostra]);
        delete this.itemsToUpdate[id_coletas_amostra];
      }
    },

    atualizaResultado(item) {
      const id_coletas_amostra = item.id_coletas_amostra;

      this.resultadoSelecionado({ id_coletas_amostra });

      this.itemsToUpdate[id_coletas_amostra] = setTimeout(() => {
        this.salvaResultado(item);

        delete this.itemsToUpdate[id_coletas_amostra];
      }, 2000);
    },

    compareChanges(item, fields) {
      for (const field of fields) {
        if (_.has(item[field], 'description')) {
          if (item[field].description != item.original[field]?.description) {
            return true;
          }
        }
        else if (_.isArray(item[field])) {
          if (!_.isEqual(item[field], item.original[field])) {
            return true;
          }
        }
        else {
          if ((item[field] || '') != (item.original[field] || '')) {
            return true;
          }
        }
      }
      return false;
    },

    async salvaResultado(item) {
      const hasChanges = this.compareChanges(item, ['avisado', 'resultado', 'antibioticName']);

      if (!hasChanges) {
        return;
      }

      try {
        this.$root.$progressBar.saving();

        await this.$axios.post(
          `/coleta/atualizaResultado`,
          this.$qs.stringify({
            id_coletas_amostra: item.id_coletas_amostra,
            avisado: item.avisado ? 1 : null,
            resultado: item.resultado,
            id_marca_comercial: item.antibioticName?.id !== 'OTHER' ? item.antibioticName?.id : null,
            marca_comercial: item.antibioticName?.description,
          })
        );

      } catch (error) {
        console.log(error);
      } finally {
        this.$root.$progressBar.hide();
        this.loadItems();
      }
    },

    async loadItems() {
      try {
        this.$root.$progressBar.loading();

        const [dateStart, dateEnd] = this.filters.dateRange;

        const { data } = await this.$axios.post(
          `/coleta/listaColetaNormativa`,
          this.$qs.stringify({
            data_inicio: dateStart,
            data_fim: dateEnd,
          })
        );

        this.items = data.map(item => {
          const types = JSON.parse(item.tipos) || [];
          item.tipos = types.length ? types : [item.tipo];

          const antibioticName = {
            id: !item.id_marca_comercial && item.marca_comercial ? 'OTHER' : item.id_marca_comercial,
            description: item.marca_comercial?.replace('OUTROS - '),
          }

          return {
            ...item,
            antibioticName,

            original: {
              ...item,
              antibioticName: { ...antibioticName },
            },
          };
        });
      } catch (e) {
        this.$snotify.error("Oops, ocorreu um erro ao carregar as amostras!", "Atenção");
        console.warn(e);
      } finally {
        this.$root.$progressBar.hide();
      }
    },

    async excludeSample(id) {
      try {
        if (
          !(await this.$root.$confirm("Atenção", "Deseja realmente Inativar/Excluir esta amostra?", { color: "red" }))
        ) {
          return;
        }
        this.$root.$progressBar.saving();

        const { data } = await this.$axios.post(`/coleta/inativaColetaAmostra`, this.$qs.stringify({
          id_coleta: id,
        }));

        if (!data.codigo) {
          throw data.mensagem || data;
        }

      } catch (error) {
        console.log(error);
        this.$snotify.error(`Oops, ocorreu um erro ao excluir a amostra!\r\n${error}`, "Atenção");
      } finally {
        this.$root.$progressBar.hide();
        this.loadItems();
      }
    },

    addSample() {
      this.showAddSample = true;
    },

    getReportTitle() {
      const [startDate, endDate] = this.filters.dateRange;
      return `Coleta de Amostras - ${moment(startDate).format('DD.MM')} - ${moment(endDate).format('DD.MM')}`;
    },

    print() {
      const title = this.getReportTitle();
      this.$refs.report.print(null, title);
    },

    exportExcel() {
      const filename = this.getReportTitle();
      this.$refs.report.exportExcel(null, filename);
    },

    async openGallery(images) {
      this.pictures = images ? JSON.parse(images).map(item => {
        return {
          'src': item.url || item,
          'thumb': item.url || item
        }
      }) : [];

      await this.$nextTick();

      this.$refs.lightbox.showImage(0);
    },

    editAnexos(item) {
      this.showAnexos = true;

      this.form = {
        id_coletas_amostra: item.id_coletas_amostra,
        attachments: item.anexos ? JSON.parse(item.anexos).map(item => {
          return {
            'url': item.url || item,
          }
        }) : [],
      };
    },

    async loadMedicines() {
      try {
        const { data } = await this.$axios.get(`/registrations/antibiotic/medicine`);

        this.medicines = data
          .filter(item => item.tipo !== 'ANTIBIOTICO')
          .map(item => ({
            id: item.id,
            description: item.descricao,
          }));

      } catch (err) {
        console.warn(err);
      }
    },

    async toggleEdit(data, field) {
      const item = data?.[field] || {}
      if (item.id === 'OTHER') {
        data[field] = {
          id: item.selected?.id,
          description: item.selected?.description,
        }
      } else {
        data[field] = {
          selected: { id: item.id, description: item.description },
          id: 'OTHER',
          description: null,
        }
      }

      await this.$nextTick();

      this.$refs[`${field}_${data.id_coletas_amostra}`].focus();
    },
  },
};
</script>
