<template>
  <v-dialog
    v-model="dialog"
    width="350"
    persistent
    transition="dialog-bottom-transition"
  >
    <v-card>
      <v-card-title>
        Teste
        <v-spacer />
        <v-btn
          icon
          small
          depressed
          @click="cancel()"
        >
          <v-icon small>
            close
          </v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text>
        <v-form
          ref="formRef"
          lazy-validation
        >
          <v-row>
            <v-col
              cols="12"
              class="py-0"
            >
              <v-text-field
                v-model="form.description"
                label="Descrição"
                :rules="[v => !!v || 'Campo obrigatório!']"
              />
            </v-col>
            <v-col
              cols="12"
              class="py-0"
            >
              <masked-text-field
                v-model="form.price"
                label="Valor"
                :mask="{ mask: Number, min: 0, scale: 4, signed: false }"
                unmask
              />
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-divider />
      <v-card-actions>
        <v-btn
          color="secondary"
          outlined
          @click="close()"
        >
          cancelar
        </v-btn>
        <v-spacer />
        <v-btn
          color="primary"
          outlined
          @click="save()"
        >
          Salvar
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script setup>
import { ref, reactive } from 'vue'
import { useUtils } from '@/Support/Composables/utils.js'
import axios from '@/Support/Resources/axios-instance.js'

import MaskedTextField from '@/Support/Components/MaskedTextField.vue'

const { notify, progressBar } = useUtils()

// eslint-disable-next-line no-undef
const emit = defineEmits(['save'])

const dialog = ref(false)

const formRef = ref()
const form = reactive({
  id: null,
  description: null,
  price: null,
})

const show = async (item = {}) => {
  form.id = item.id
  form.description = item.description
  form.price = item.price
  dialog.value = true
}

const save = async () => {
  try {
    if (!await formRef.value?.validate()) {
      return
    }

    progressBar?.saving()

    const payload = {
      descricao: form.description,
      valor: form.price,
    };

    form.id
      ? await axios.put(`/registrations/antibiotic/test/${form.id}`, payload)
      : await axios.post(`/registrations/antibiotic/test/`, payload)

    emit('save')
    close()
  } catch (e) {
    console.error(e)
    notify.error('Oops, ocorreu um erro ao salvar!', 'Atenção')
  } finally {
    progressBar?.hide()
  }
}

const close = () => {
  form.id = null
  form.description = null
  dialog.value = false
}

// eslint-disable-next-line no-undef
defineExpose({ show })
</script>

