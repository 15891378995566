<template>
  <v-dialog
    v-model="show"
    width="600"
    max-width="800"
  >
    <v-card>
      <v-card-title>Cadastro de Amostra</v-card-title>

      <v-card-text>
        <v-form
          ref="form"
        >
          <person-autocomplete-filter
            v-model="form.producer"
            label="Produtor *"
            type="PRODUCER"
            :filled="false"
            :hide-details="false"
            show-groupings
            show-bonus-producers
            :rules="[v => !!v || 'Campo obrigatório!']"
          />

          <date-text-field
            v-model="form.date"
            label="Data da amostra *"
            prepend-inner-icon="event"
            no-title
            :rules="[v => !!v || 'Campo obrigatório!']"
          />

          <v-select
            v-if="!type"
            v-model="form.types"
            :items="sampleTypes"
            label="Tipos *"
            prepend-inner-icon="biotech"
            multiple
            :rules="[v => v?.length > 0 || 'Campo obrigatório!']"
          />

          <v-text-field
            v-model="form.sampleNumber"
            :type="usesFreeText ? 'text' : 'number'"
            label="Número da amostra *"
            prepend-inner-icon="add"
            :rules="[v => !!v || 'Campo obrigatório!']"
          />

          <person-autocomplete-filter
            v-model="form.analyst"
            label="Analista"
            type="ANALYST"
            :filled="false"
            :hide-details="false"
          />

          <v-text-field
            v-if="type === 'Antibioticos'"
            v-model="form.antibioticBatch"
            label="Lote"
            prepend-inner-icon="icon-estoque"
          />

          <date-text-field
            v-model="form.dateAnalysis"
            label="Data da análise"
            prepend-inner-icon="event"
            no-title
          />

          <v-text-field
            v-model="form.obs"
            label="Observações"
            prepend-inner-icon="edit"
          />

          <v-autocomplete
            v-model="form.antibioticName"
            label="Medicamento"
            :items="medicines"
            item-value="id"
            item-text="description"
            prepend-inner-icon="vaccines"
            return-object
            clearable
            @input="onAntibioticChange"
          />

          <v-text-field
            v-if="form.antibioticName?.id === 'OTHER'"
            v-model="form.otherAntibioticName"
            label="Medicamento (Outros) *"
            prepend-inner-icon="add"
            :rules="[v => !!v || 'Campo obrigatório!']"
          />

          <template v-if="type === 'Antibioticos'">
            <v-autocomplete
              v-model="form.activeIngredient"
              label="Princípio Ativo"
              :items="activeIngredients"
              item-value="id"
              item-text="description"
              prepend-inner-icon="bloodtype"
              return-object
              clearable
              :disabled="form.antibioticName?.id && form.antibioticName.id !== 'OTHER'"
              @input="onActiveIngredientChange"
            />

            <v-text-field
              v-if="form.activeIngredient?.id === 'OTHER'"
              v-model="form.otherActiveIngredient"
              label="Princípio Ativo (Outros) *"
              prepend-inner-icon="add"
              :rules="[v => !!v || 'Campo obrigatório!']"
            />

            <v-autocomplete
              :value="form.groups"
              label="Grupo/Família"
              :items="groups"
              item-value="id"
              item-text="description"
              prepend-inner-icon="group_work"
              return-object
              clearable
              multiple
              chips
              :disabled="form.activeIngredient?.id && form.activeIngredient.id !== 'OTHER'"
            />
          </template>

          <v-row>
            <v-col
              v-for="(attachment, idx) in form.attachments"
              :key="idx"
            >
              <file-viewer
                :value="attachment"
                @removeFile="onRemoveFile(idx)"
              />
            </v-col>
            <v-col
              v-if="form.attachments.length < 3"
              class="py-0 text-center align-self-center flex-column justify-center"
            >
              <v-btn
                outlined
                class="mb-5"
                @click="showFileUpload = !showFileUpload"
              >
                <v-icon>attach_file</v-icon>
                <span class="ml-2">Adicionar</span>
              </v-btn>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-divider />
      <v-card-actions>
        <v-spacer />
        <v-btn
          class="black--text"
          text
          @click="close()"
        >
          cancelar
        </v-btn>
        <v-btn
          class="white--text"
          color="blue"
          text
          @click="save()"
        >
          Salvar
        </v-btn>
      </v-card-actions>
    </v-card>

    <file-upload
      v-model="showFileUpload"
      extensions="pdf,xml,gif,jpe,jpeg,jpg,tiff,png,webp,bmp"
      accept="application/pdf,text/xml,image/*"
      :multiselect="false"
      @insertFiles="onSelectFiles"
    />
  </v-dialog>
</template>

<script>
import PersonAutocompleteFilter from "@/Support/Components/Filters/PersonAutocompleteFilter.vue";
import DateTextField from "@/Support/Components/DateTextField.vue";
import FileUpload from "@/Support/Components/FileUpload.vue";
import FileViewer from "@/Support/Components/FileViewer.vue";

import moment from "moment-timezone";

export default {
  components: {
    PersonAutocompleteFilter,
    DateTextField,
    FileUpload,
    FileViewer
  },

  props: {
    value: Boolean,
    type: String,
  },

  data() {
    return {
      showFileUpload: false,
      form: {
        date: moment().format('YYYY-MM-DD'),
        dateAnalysis: moment().format('YYYY-MM-DD'),
        types: [],
        attachments: [],
        antibioticName: {},
        otherAntibioticName: null,
        activeIngredient: {},
        otherActiveIngredient: null,
        groups: [],
      },

      medicines: [],
      activeIngredients: [],
      groups: [],
    }
  },

  computed: {
    show: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },

    usesFreeText() {
      return this.$store.state.settings.gerais?.ativa_texto_livre_amostra || false
    },

    sampleTypes() {
      const types = this.$store.state.settings.gerais?.tipos_coleta_amostra || [];

      return types.filter(type => type !== 'ANTIBIOTICOS')
    },
  },

  created() {
    if (this.type === 'Antibioticos') {
      this.loadAntibiotics();
    } else {
      this.loadMedicines();
    }
  },

  methods: {

    async save() {
      try {
        if (!await this.$refs.form.validate()) {
          return;
        }

        this.$root.$progressBar.saving();

        const payload = {
          id_produtor: this.form.producer.id,
          nome_produtor: this.form.producer.name,
          observacao: this.form.obs,
          numero_amostra: this.form.sampleNumber,
          id_marca_comercial: this.form.antibioticName?.id !== 'OTHER' ? this.form.antibioticName?.id : null,
          marca_comercial: this.form.antibioticName?.id === 'OTHER' ? `OUTROS - ${this.form.otherAntibioticName}` : this.form.antibioticName?.description,
          id_principio_ativo: this.form.activeIngredient?.id !== 'OTHER' ? this.form.activeIngredient?.id : null,
          principio_ativo: this.form.activeIngredient?.id === 'OTHER' ? `OUTROS - ${this.form.otherActiveIngredient}` : this.form.activeIngredient?.description,
          grupos: this.form.groups.map(group => ({
            id: group.id,
            descricao: group.description,
          })),
          anexos: this.form.attachments,
          data: this.form.date,
          tipo: this.type || this.form.types[0],
          tipos: this.form.types,
          id_analista: this.form.analyst?.id,
          analista: this.form.analyst?.name,
          lote_antibiotico: this.form.antibioticBatch,
          data_analise: this.form.dateAnalysis,
        };

        const { data } = await this.$axios.post(`/coleta/insereColetaNormativa`, payload);

        this.$emit('save', data.id);
        this.close();
        this.$snotify.success("Amostra salva com sucesso!", "Sucesso");
      } catch (error) {
        this.$snotify.error(error, "Atenção");
        console.warn(error);
      } finally {
        this.$root.$progressBar.hide();
      }
    },

    close() {
      this.form = {
        date: moment().format('YYYY-MM-DD'),
        dateAnalysis: moment().format('YYYY-MM-DD'),
        attachments: []
      };
      this.$refs.form?.resetValidation();
      this.show = false;
    },

    onSelectFiles(files) {
      for (let file of files) {
        if (!this.form.attachments.find(o => o.name == file.name)) {
          this.form.attachments.push(file);
        }
      }
    },

    async onRemoveFile(idx) {
      try {
        const fileUrl = this.form.attachments[idx].url;
        if (fileUrl.includes('googleapis.com') && fileUrl.includes('exames')) {
          if (!(await this.$root.$confirm('Remover anexo', 'Tem certeza que deseja excluir este anexo?', { color: 'red' }))) {
            return;
          }
          await this.$axios.post(`/arquivos/storageDelete`, { fileUrl });
          this.form.attachments.splice(idx, 1);
          await this.save();
        }
        else {
          this.form.attachments.splice(idx, 1);
        }
      } catch (err) {
        console.warn(err);
      }
    },

    async loadMedicines() {
      try {
        const { data } = await this.$axios.get(`/registrations/antibiotic/medicine`);

        this.medicines = data
          .filter(item => item.tipo !== 'ANTIBIOTICO')
          .map(item => ({
            id: item.id,
            description: item.descricao,
          }));

        this.medicines.push({ id: 'OTHER', description: 'OUTROS' });

      } catch (err) {
        console.warn(err);
      }
    },

    async loadAntibiotics() {
      try {
        const [{ data: medicines }, { data: activeIngredients }, { data: groups }] = await Promise.all([
          this.$axios.get(`/registrations/antibiotic/medicine`),
          this.$axios.get(`/registrations/antibiotic/active-ingredient`),
          this.$axios.get(`/registrations/antibiotic/group`),
        ]);

        this.medicines = medicines
          .filter(item => item.tipo === 'ANTIBIOTICO')
          .map(item => ({
            id: item.id,
            description: item.descricao,
            activeIngredientId: item.id_principio_ativo,
            activeIngredient: {
              id: item.principio_ativo?.id,
              description: item.principio_ativo?.descricao,
              groups: item.principio_ativo?.grupos?.map(group => ({
                id: group.id,
                description: group.descricao
              })) || [],
              tests: item.principio_ativo?.testes,
            },
          }));

        this.activeIngredients = activeIngredients.map(item => ({
          id: item.id,
          description: item.descricao,
          groups: item.grupos?.map(group => ({
            id: group.id,
            description: group.descricao
          })) || [],
          tests: item.testes,
        }));

        this.groups = groups.map(item => ({
          id: item.id,
          description: item.descricao,
        }));

        this.medicines.push({ id: 'OTHER', description: 'OUTROS' });
        this.activeIngredients.push({ id: 'OTHER', description: 'OUTROS' });

      } catch (err) {
        console.warn(err);
      }
    },

    onAntibioticChange(item) {
      if (!item || item.id === 'OTHER' || this.type !== 'Antibioticos') {
        return;
      }

      this.form.activeIngredient = item.activeIngredient;
      this.form.groups = item.activeIngredient?.groups || [];
    },

    onActiveIngredientChange(item) {
      if (!item || item.id === 'OTHER') {
        return;
      }

      this.form.groups = item.groups || [];
    },
  }
}
</script>
