<template>
  <v-dialog
    v-model="dialog"
    width="350"
    persistent
    transition="dialog-bottom-transition"
  >
    <v-card>
      <v-card-title>
        Princípio Ativo
        <v-spacer />
        <v-btn
          icon
          small
          depressed
          @click="cancel()"
        >
          <v-icon small>
            close
          </v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text>
        <v-form
          ref="formRef"
          lazy-validation
        >
          <v-row>
            <v-col
              cols="12"
              class="py-0"
            >
              <v-autocomplete
                v-model="form.groupsIds"
                :items="groups"
                label="Grupos/Famílias"
                item-value="id"
                item-text="description"
                multiple
                chips
                :rules="[v => v?.length > 0 || 'Campo obrigatório!']"
              />
            </v-col>
            <v-col
              cols="12"
              class="py-0"
            >
              <v-text-field
                v-model="form.description"
                label="Descrição"
                :rules="[v => !!v || 'Campo obrigatório!']"
              />
            </v-col>
            <v-col
              cols="12"
              class="py-0"
            >
              <v-autocomplete
                v-model="form.testsIds"
                :items="tests"
                label="Testes"
                item-value="id"
                item-text="description"
                multiple
                chips
              />
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-divider />
      <v-card-actions>
        <v-btn
          color="secondary"
          outlined
          @click="close()"
        >
          cancelar
        </v-btn>
        <v-spacer />
        <v-btn
          color="primary"
          outlined
          @click="save()"
        >
          Salvar
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script setup>
import { ref, reactive } from 'vue'
import { useUtils } from '@/Support/Composables/utils.js'
import axios from '@/Support/Resources/axios-instance.js'

const { notify, progressBar } = useUtils()

// eslint-disable-next-line no-undef
const emit = defineEmits(['save'])

const dialog = ref(false)

const formRef = ref()
const form = reactive({
  id: null,
  groupsIds: [],
  description: null,
  testsIds: [],
})

const show = async (item = {}) => {
  form.id = item.id
  form.groupsIds = item.groupsIds || []
  form.description = item.description
  form.testsIds = item.testsIds || []
  dialog.value = true
}

const save = async () => {
  try {
    if (!await formRef.value?.validate()) {
      return
    }

    progressBar?.saving()

    const payload = {
      ids_grupos: form.groupsIds,
      descricao: form.description,
      ids_testes: form.testsIds,
    };

    form.id
      ? await axios.put(`/registrations/antibiotic/active-ingredient/${form.id}`, payload)
      : await axios.post(`/registrations/antibiotic/active-ingredient/`, payload)

    emit('save')
    close()
  } catch (e) {
    console.error(e)
    notify.error('Oops, ocorreu um erro ao salvar!', 'Atenção')
  } finally {
    progressBar?.hide()
  }
}

const close = () => {
  form.id = null
  form.description = null
  dialog.value = false
}

const groups = ref([])

const loadGroups = async () => {
  try {
    const { data } = await axios.get(`/registrations/antibiotic/group`)

    groups.value = data.map(item => ({
      id: item.id,
      description: item.descricao,
    }))
  } catch (err) {
    console.warn(err)
    notify.error('Oops, ocorreu um erro ao carregar os grupos!', 'Atenção')
  }
}

const tests = ref([])

const loadTests = async () => {
  try {
    const { data } = await axios.get(`/registrations/antibiotic/test`)

    tests.value = data.map(item => ({
      id: item.id,
      description: item.descricao,
    }))
  } catch (err) {
    console.warn(err)
    notify.error('Oops, ocorreu um erro ao carregar os grupos!', 'Atenção')
  }
}

loadGroups()
loadTests()

// eslint-disable-next-line no-undef
defineExpose({ show })
</script>

